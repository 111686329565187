import { v4 as uuid, v4 } from "uuid"
import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { TaskStatus } from "../constants/taskStatus"
import { AgreementType } from "../agreement/constants/agreementType"
import { IsNotEmpty } from "class-validator"

@Serializable
export class MainTaskModel {
  uid: string = v4()

  @IsNotEmpty({ message: "error_fill", groups: ["agreement_information"] })
  name: string = ""
  agreementNumber: string = ""
  description: string = ""
  state: string = TaskStatus.Drafted

  @IsNotEmpty({ message: "error_fill", groups: ["agreement_type"] })
  agreementType: number | null = null
  @IsNotEmpty({ message: "error_fill", groups: ["agreement_owner"] })
  agreementOwnerId: string = ""
  agreementOwnerName: string = ""
  @IsNotEmpty({ message: "error_fill", groups: ["about_customer"] })
  customerId: string = ""
  customerName: string = ""
  @IsNotEmpty({ message: "error_fill", groups: ["dates"] })
  startDate: string = ""
  @IsNotEmpty({ message: "error_fill", groups: ["dates"] })
  endDate: string = ""
  hasExtensionOption: boolean = false
  numOptionYears: number = 0
  priceAdjustmentDay: number = 1
  priceAdjustmentMonth: number = 1
  stepIndex: number = 1
  isWizzardCompleted: boolean = false

  constructor(
    data: {
      uid: string
      name: string
      agreementNumber: string
      description: string
      state: string
      agreementType: number
      agreementOwnerId: string
      agreementOwnerName: string
      customerId: string
      customerName: string
      startDate: string
      endDate: string
      hasExtensionOption: boolean
      numOptionYears: number
      priceAdjustmentDay: number
      priceAdjustmentMonth: number
      stepIndex: number
      isWizzardCompleted: boolean
    } | null = null,
  ) {
    if (data) {
      this.uid = data.uid
      this.name = data.name
      this.agreementNumber = data.agreementNumber
      this.description = data.description
      this.state = data.state
      this.agreementType = data.agreementType
      this.agreementOwnerId = data.agreementOwnerId
      this.agreementOwnerName = data.agreementOwnerName
      this.customerId = data.customerId
      this.customerName = data.customerName
      this.startDate = data.startDate
      this.endDate = data.endDate
      this.hasExtensionOption = data.hasExtensionOption
      this.numOptionYears = data.numOptionYears
      this.priceAdjustmentDay = data.priceAdjustmentDay
      this.priceAdjustmentMonth = data.priceAdjustmentMonth
      this.stepIndex = data.stepIndex
      this.isWizzardCompleted = data.isWizzardCompleted
    }
  }
}
