import { Serializable } from "@/core/api/decorators/serializable_decorator"

@Serializable
export class AssignedContact {
  email: string = ""
  name: string = ""
  title: string = ""
  space_user_uid: string = ""
  role: string[] = []

  constructor(
    data: {
      email: string
      name: string
      title: string
      space_user_uid: string
      role: string[]
    } | null = null,
  ) {
    if (data) {
      this.email = data.email
      this.name = data.name
      this.title = data.title
      this.space_user_uid = data.space_user_uid
      this.role = data.role
    }
  }
}
/*
export class AssignedContact {
  email: string
  name: string
  title: string
  space_user_uid: string
  role: string[]

  constructor(
    email: string,
    name: string,
    title: string,
    space_user_uid: string,
    role: string[],
  ) {
    this.email = email
    this.name = name
    this.title = title
    this.space_user_uid = space_user_uid
    this.role = role
  }
}
*/
