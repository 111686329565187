import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { LeadListView } from "../../supplier/models/leadListView"

@Serializable
export class CustomerMainTaskListView {
  uid: string = ""
  name: string = ""
  startDate: string = ""
  endDate: string = ""
  customerId: string = ""
  customerName: string = ""
  agreementOwnerId: string = ""
  agreementOwnerName: string = ""
  hasExtensionOption: boolean = false
  isWizzardCompleted: boolean = false
  leads: LeadListView[] = []

  constructor(
    data: {
      uid: string
      name: string
      startDate: string
      endDate: string
      customerId: string
      customerName: string
      agreementOwnerId: string
      agreementOwnerName: string
      hasExtensionOption: boolean
      isWizzardCompleted: boolean
      leads: any[]
    } | null = null,
  ) {
    if (data) {
      this.uid = data.uid
      this.name = data.name
      this.customerId = data.customerId
      this.customerName = data.customerName
      this.agreementOwnerId = data.agreementOwnerId
      this.agreementOwnerName = data.agreementOwnerName
      this.hasExtensionOption = data.hasExtensionOption
      this.startDate = data.startDate
      this.endDate = data.endDate
      this.isWizzardCompleted = data.isWizzardCompleted
      this.leads = data.leads
    }
  }
}
