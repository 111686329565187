import { v4 as uuid } from "uuid"

export class PriceCategoryBaseModel {
  uid: string = uuid()
  parentId: string
  name: string = ""
  subtitle: string = ""
  priceType: number = 0

  constructor(
    data: {
      uid: string
      parentId: string
      name: string
      subtitle: string
      priceType: number
    } | null = null,
  ) {
    if (data) {
      this.uid = data.uid
      this.parentId = data.parentId
      this.name = data.name
      this.subtitle = data.subtitle
      this.priceType = data.priceType
    }
  }
}
