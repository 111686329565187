import { Serializable } from "@/core/api/decorators/serializable_decorator"

@Serializable
export class SupplierSelectionTaskListView {
  uid: string = ""
  name: string = ""
  selected: boolean = false
  numSelected: number = 0

  constructor(
    data: {
      uid: string
      name: string
      selected: boolean
      numSelected: number
    } | null = null,
  ) {
    if (data) {
      this.uid = data.uid
      this.name = data.name
      this.selected = data.selected
      this.numSelected = data.numSelected
    }
  }
}
