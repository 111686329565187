import { File } from "@/core/models/file"
import { ContractPartyListView } from "./contractPartyListView"
import { ContractStatus } from "../constants/contractStatus"

export class ContractDetails {
  uid: string = ""
  title: string = ""
  ownerId: string = ""
  templateId: string = ""
  templateName: string = ""
  content: string = ""
  customer: {
    uid: string
    name: string
    reference: string
  } | null = null
  properties: Record<string, any> = {}
  parties: ContractPartyListView[] = []
  startDate: string = ""
  endDate: string = ""
  status: ContractStatus = ContractStatus.Draft
  isAdministrator: boolean = false
  isOwner: boolean = false
  attachments: File[] = []
  created: Date = new Date()

  constructor(
    data: {
      uid: string
      title: string
      ownerId: string
      templateId: string
      templateName: string
      content: string
      customer: {
        uid: string
        name: string
        reference: string
      } | null
      parties: ContractPartyListView[]
      properties: Record<string, any>
      startDate: string
      endDate: string
      status: ContractStatus
      isAdministrator: boolean
      isOwner: boolean
      attachments: File[]
      created: Date
    } | null = null,
  ) {
    if (data) {
      this.uid = data.uid
      this.title = data.title
      this.ownerId = data.ownerId
      this.templateId = data.templateId
      this.templateName = data.templateName
      this.content = data.content
      this.customer = data.customer
      this.properties = data.properties
      this.parties = data.parties
      this.startDate = data.startDate
      this.endDate = data.endDate
      this.status = data.status
      this.isAdministrator = data.isAdministrator
      this.isOwner = data.isOwner
      this.attachments = data.attachments
      this.created = data.created
        ? new Date(data.created.toString())
        : new Date()
    }
  }
}
