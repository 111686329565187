import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { CustomerMainTaskSimpleListView } from "./customerMainTaskSimpleListView"

@Serializable
export class CustomerListView {
  uid: string = ""
  name: string = ""
  mainTasks: CustomerMainTaskSimpleListView[] = []

  constructor(
    data: {
      uid: string
      name: string
      mainTasks: any[]
    } | null = null,
  ) {
    if (data) {
      this.uid = data.uid
      this.name = data.name
      this.mainTasks = data.mainTasks
    }
  }
}
