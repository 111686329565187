import { Serializable } from "@/core/generated/api/decorators/serializable_decorator"
import { StringUtil } from "@/core/utils/string_util"

@Serializable
export class SupplierGroup {
  uid: string = ""
  name: string = ""
  // ISO Strings
  createdAt: string = ""
  updatedAt: string = ""
  deletedAt: string = ""

  constructor(
    data: {
      name: string
    } | null = null,
  ) {
    if (data) {
      this.name = StringUtil.getSafeString(data.name)
    }
  }
}
