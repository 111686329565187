import "reflect-metadata"
import { container, InjectionToken } from "tsyringe"
import { FetchClient } from "@/core/client/fetch_client"
import { FetchSettings } from "@/core/client/fetch_settings"
import { registerClass } from "@/core/generated/api/decorators/serializable_decorator"
import { PagingModel } from "@/core/generated/api/models/paging_model"
import { ContractDetails } from "@/modules/contracts/models/contractDetails"
import { CreateContract } from "@/modules/contracts/models/createContract"
import { SupplierGroup } from "@/modules/spaces/models/supplierGroup_model"
import { TaskSidebarModel } from "@/modules/tasks/models/taskSideBarModel"
import { TaskTemplateListView } from "@/modules/tasks/models/taskTemplateListView"
import { TaskProvider } from "@/modules/tasks/providers/taskProvider"
import { TaskRepository } from "@/modules/tasks/repositories/taskRepository"
import { OpportunityListView } from "@/modules/tasks/supplier/models/opportunityListView"
import { CacheProvider } from "@/shared/cache/cacheProvider"
import { MainTaskSaveArgs } from "@/modules/tasks/models/args/mainTaskSaveArgs"
import { register } from "module"
import { MainTaskModel } from "@/modules/tasks/models/mainTaskModel"
import { ContactModel } from "@/modules/tasks/models/contactModel"
import { ContactExtModel } from "@/modules/tasks/models/contactExtModel"
import { PriceRootModel } from "@/modules/prices/models/priceRootModel"
import { SubAreaModel } from "@/modules/prices/models/subAreaModel"
import { PriceCategoryFixedModel } from "@/modules/prices/models/categories/priceCategoryFixedModel"
import { PriceCategoryFreeModel } from "@/modules/prices/models/categories/priceCategoryFreeModel"
import { PriceCategoryIntervalModel } from "@/modules/prices/models/categories/priceCategoryIntervalModel"
import { PriceCutModel } from "@/modules/prices/models/priceCutModel"
import { SupplierSubAreaModel } from "@/modules/prices/models/supplierSubAreaModel"
import { FileAttachment } from "@/modules/tasks/customer/models/fileAttachment"
import { SupplierListView } from "@/modules/tasks/supplier/models/supplierListView"
import { SupplierMainTaskPagingModel } from "@/modules/tasks/supplier/models/supplierMainTaskPaging"
import { SupplierMainTaskSelectedPagingModel } from "@/modules/tasks/supplier/models/supplierMainTaskSelectedPaging"
import { SupplierSelectionTaskListView } from "@/modules/tasks/supplier/models/supplierSelectionTaskListView"
import { SupplierTaskListView } from "@/modules/tasks/supplier/models/supplierTaskListView"
import { LeadListView } from "@/modules/tasks/supplier/models/leadListView"
import { RFQTaskModel } from "@/modules/tasks/models/rfqTaskModel"
import { CustomerListView } from "@/modules/tasks/customer/models/customerListView"
import { CustomerMainTaskListView } from "@/modules/tasks/customer/models/customerMainTaskListView"
import { CustomerMainTaskSimpleListView } from "@/modules/tasks/customer/models/customerMainTaskSimpleListView"
import { CustomerTaskListView } from "@/modules/tasks/customer/models/customerTaskListView"
import { SimpleModel } from "@/modules/minibids/models/simpleModel"
import { MinibidTask } from "@/modules/minibids/models/minibidTask"
import { AssignedContact } from "@/modules/minibids/models/assignedContacts"

export const useStartup = () => {
  container.register(FetchSettings, { useClass: FetchSettings })
  container.register(FetchClient, {
    useValue: new FetchClient(getService(FetchSettings)),
  })

  container.register(TaskRepository, {
    useValue: new TaskRepository(getService(FetchClient)),
  })
  container.register(TaskProvider, {
    useValue: new TaskProvider(
      getService(CacheProvider),
      getService(TaskRepository),
    ),
  })

  registerClass(PagingModel)
  registerClass(TaskSidebarModel)
  registerClass(TaskTemplateListView)
  registerClass(OpportunityListView)
  registerClass(ContractDetails)
  registerClass(CreateContract)
  registerClass(SupplierGroup)
  registerClass(MainTaskSaveArgs)
  registerClass(MainTaskModel)
  registerClass(ContactModel)
  registerClass(ContactExtModel)
  registerClass(PriceRootModel)
  registerClass(SubAreaModel)
  registerClass(PriceCategoryFixedModel)
  registerClass(PriceCategoryFreeModel)
  registerClass(PriceCategoryIntervalModel)
  registerClass(PriceCutModel)
  registerClass(SubAreaModel)
  registerClass(SupplierSubAreaModel)
  registerClass(FileAttachment)
  registerClass(SupplierListView)
  registerClass(SupplierMainTaskPagingModel)
  registerClass(SupplierMainTaskSelectedPagingModel)
  registerClass(SupplierSelectionTaskListView)
  registerClass(SupplierTaskListView)
  registerClass(LeadListView)
  registerClass(RFQTaskModel)
  registerClass(CustomerListView)
  registerClass(CustomerMainTaskListView)
  registerClass(CustomerMainTaskSimpleListView)
  registerClass(CustomerTaskListView)
  registerClass(SimpleModel)
  registerClass(AssignedContact)
  registerClass(MinibidTask)
}

export const getService = <T>(token: InjectionToken<T>): T => {
  return container.resolve(token)
}
