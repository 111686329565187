import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
} from "class-validator"

export function IsAcceptancePeriodRequired(
  validationOptions?: ValidationOptions,
) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: "isAcceptancePeriodRequired",
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: any, args: ValidationArguments) {
          const object = args.object as any
          return object.intermediateSale === "true" ? value !== "" : true
        },
        defaultMessage(args: ValidationArguments) {
          return "Acceptance period is required when intermediate sale is true"
        },
      },
    })
  }
}
