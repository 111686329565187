import { Serializable } from "@/core/api/decorators/serializable_decorator"

@Serializable
export class FileAttachment {
  uid: string = ""
  filename: string = ""
  mimetype: string = ""
  path: string = ""
  folder: string = ""

  constructor(
    data: {
      uid: string
      filename: string
      mimetype: string
      path: string
      folder: string
    } | null = null,
  ) {
    if (data) {
      this.uid = data.uid
      this.filename = data.filename
      this.mimetype = data.mimetype
      this.path = data.path
      this.folder = data.folder
    }
  }
}
