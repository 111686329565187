import { v4 as uuid } from "uuid"
import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { ArrayMinSize, IsArray, IsDefined, IsNotEmpty } from "class-validator"
import { PriceCategoryBaseModel } from "./categories/priceCategoryBaseModel"

@Serializable
export class SubAreaModel {
  uid: string = uuid()
  @IsNotEmpty({ groups: ["subareas"] })
  @IsDefined({ groups: ["subareas"] })
  name: string = ""
  headline: string = ""
  headlineNumber: number = 1
  @IsArray({
    message: "create_proposal:prices.choose_consultant_category_error",
    groups: ["prices"],
  })
  @ArrayMinSize(1, {
    message: "create_proposal:prices.choose_consultant_category_error",
    groups: ["prices"],
  })
  categories: PriceCategoryBaseModel[]

  constructor(data?: Partial<SubAreaModel>) {
    if (data) {
      this.uid = data.uid || uuid()
      this.name = data.name
      this.headline = data.headline
      this.headlineNumber = data.headlineNumber
      this.categories = data.categories
    }
  }
}
