import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { SubAreaModel } from "./subAreaModel"
import { PriceCutModel } from "./priceCutModel"
import { StringUtil } from "@/core/utils/string_util"

@Serializable
export class SupplierSubAreaModel extends SubAreaModel {
  selected: boolean = true
  supplierId: string = ""
  supplierName: string = ""
  supplierPriceCut: PriceCutModel | null = null

  constructor(
    data: {
      uid: string
      name: string
      headline: string
      headlineNumber: number
      selected: boolean
      categories: any[]
      supplierId: string
      supplierName: string
      supplierPriceCut: any
    } | null = null,
  ) {
    super(data)
    if (data) {
      this.selected = Boolean(data.selected)
      this.supplierId = StringUtil.getSafeString(data.supplierId)
      this.supplierName = StringUtil.getSafeString(data.supplierName)
      if (data.supplierPriceCut) {
        this.supplierPriceCut = new PriceCutModel(data.supplierPriceCut)
      }
    }
  }
}
