import { meAtom } from "@/atoms/users"
import { INotification } from "@/features/notifications/types"
import { setNotifications } from "hooks/queries/notificationQueries"
import { queryClient } from "pages/_app"
import { useEffect } from "react"
import { useRecoilValue } from "recoil"
import { toast } from "sonner"
import { selectedSpaceAtom } from "../atoms/spaces"
import { usePusher } from "../context/pusher-context"

export const NotificationsProvider = () => {
  const me = useRecoilValue(meAtom)
  const spaceSelected = useRecoilValue(selectedSpaceAtom)
  const { subscribe, bind } = usePusher()

  const subscribeChannels = async () => {
    await Promise.all([
      subscribe({ channel: `user.${me.uid}`, name: `User: ${me.firstname}` }),
      subscribe({
        channel: `space_user.${me.space_user_uid}`,
        name: `Space user: ${me.firstname}`,
      }),
      subscribe({
        channel: `space.${spaceSelected.uid}`,
        name: `Space: ${spaceSelected.name}`,
      }),
    ])

    if (spaceSelected.uid) {
      bind({
        event: "notifications",
        channel: `space_user.${me.space_user_uid}`,
        callback: (data) => {
          // Update notifications and notification badge count
          queryClient.invalidateQueries({
            queryKey: ["notifications"],
          })
          promptNotification(data)
          toast(
            <div className="col-span-10 w-full">
              <p className="text-lg">New notification</p>
              <p
                className="text-gray-custom-dark text-sm"
                dangerouslySetInnerHTML={{ __html: data.title }}
              />
            </div>,
          )
        },
      })
    }
  }

  useEffect(() => {
    if (!me?.is_employee || !spaceSelected) return
    subscribeChannels()
  }, [me, spaceSelected])

  const promptNotification = (notification: INotification) => {
    setNotifications((prev) => [notification, ...(prev || [])])
  }

  return null
}
