import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { SupplierSubAreaModel } from "@/modules/prices/models/supplierSubAreaModel"
import { ContactModel } from "../../models/contactModel"
import { PriceCutModel } from "@/modules/prices/models/priceCutModel"
import { StringUtil } from "@/core/utils/string_util"

@Serializable
export class SupplierTaskListView {
  uid: string = ""
  name: string = ""
  contacts: ContactModel[] = []
  subAreas: SupplierSubAreaModel[] = []
  priceCut: PriceCutModel | null = null

  constructor(
    data: {
      uid: string
      name: string
      contacts: ContactModel[]
      subAreas: SupplierSubAreaModel[]
      priceCut: PriceCutModel
    } | null = null,
  ) {
    if (data) {
      this.uid = StringUtil.getSafeString(data.uid)
      this.name = StringUtil.getSafeString(data.name)
      this.contacts = data.contacts
      this.subAreas = data.subAreas
      if (data.priceCut) {
        this.priceCut = new PriceCutModel(data.priceCut)
      }
    }
  }
}
