import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { StringUtil } from "@/core/utils/string_util"

@Serializable
export class SimpleModel {
  uid: string = ""
  name: string = ""

  constructor(
    data: {
      uid: string
      name: string
    } | null = null,
  ) {
    if (data) {
      this.uid = StringUtil.getSafeString(data.uid)
      this.name = StringUtil.getSafeString(data.name)
    }
  }
}
