import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { PriceCategoryBaseModel } from "./priceCategoryBaseModel"

@Serializable
export class PriceCategoryFixedModel extends PriceCategoryBaseModel {
  amount: number = 0

  constructor(
    data: {
      uid: string
      parentId: string
      name: string
      subtitle: string
      priceType: number
      amount: number
    } | null = null,
  ) {
    super(data)
    if (data) {
      this.amount = data.amount
    }
  }
}
