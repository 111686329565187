import { Base64 } from "js-base64"
import { Dictionary } from "@/core/collections/dictionary"

export class JsonUtil {
  public static deserializeJson64Array<T>(data: string): T[] | null {
    if (!data) return null

    let dataModel: any = Base64.decode(data)
    let items: Array<T> = JSON.parse(dataModel)

    return items
  }

  public static deserializeJson64<T>(
    data: string,
    classes: Dictionary<any> | null = null,
    funcClassType: (className: string) => T | null = () => {
      return null
    },
  ): T | null {
    if (!data) return null

    let dataModel: any = Base64.decode(data)
    let item: T
    if (classes) {
      item = JsonUtil.deserialize<T>(dataModel, classes)
    } else {
      const itemTemp = JSON.parse(dataModel)
      if (itemTemp.__type !== undefined) {
        item = JsonUtil.deserializeClassType<T>(dataModel, funcClassType)
      } else {
        item = itemTemp
      }
    }

    return item
  }

  public static serializeJson64<T>(model: T): string {
    const json: string = JsonUtil.serialize<T>(model)
    return Base64.encode(json)
  }

  // public static serialize<T>(model: T): string {
  //   return JSON.stringify(model, (key, value) => {
  //     if (value && typeof value === "object" && !Array.isArray(value)) {
  //       value.__type = value.constructor.name
  //     }

  //     return value
  //   })
  // }
  public static serialize<T>(model: T): string {
    if ((model as any).constructor.__type) {
      ;(model as any).__type = (model as any).constructor.__type
    }

    return JSON.stringify(model, (key, value) => {
      if (value && typeof value === "object" && !Array.isArray(value)) {
        if (!(value.constructor as any).__type) {
          value.__type = value.constructor.name
        } else {
          value.__type = (value.constructor as any).__type
        }
      }

      return value
    })
  }

  public static deserialize<T>(data: string, classes: Dictionary<any>): T {
    let model: T = JSON.parse(data, (key, value) => {
      if (value && typeof value === "object" && value.__type) {
        const ctor = classes[value.__type]
        if (ctor) {
          value = Object.assign(new ctor(), value)
        }
        delete value.__type
      }

      return value
    })

    return model
  }

  public static deserializeClassType<T>(
    data: string,
    funcClassType: (className: string) => any,
  ): T {
    let model: T = JSON.parse(data, (key, value) => {
      if (value && typeof value === "object" && value.__type) {
        const ctor = funcClassType(value.__type)
        if (ctor) {
          value = Object.assign(new ctor(), value)
        }
        delete value.__type
      }

      return value
    })

    return model
  }

  public static serializeToJson<T>(
    model: T,
    addClassNames: boolean = false,
  ): any {
    return addClassNames
      ? JSON.parse(JsonUtil.serialize<T>(model))
      : JSON.parse(JSON.stringify(model))
  }
}
