import { v4 as uuid } from "uuid"
import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { PriceCutType } from "../constants/priceCutType"

@Serializable
export class PriceCutModel {
  uid: string = uuid()
  amount: number = 0
  amountCutType: number = PriceCutType.Percentage

  constructor(
    data: {
      uid: string
      amount: number
      amountCutType: number
    } | null = null,
  ) {
    if (data) {
      this.uid = data.uid
      this.amount = data.amount
      this.amountCutType = data.amountCutType
    }
  }
}
