import { Serializable } from "@/core/api/decorators/serializable_decorator"

@Serializable
export class ContactModel {
  spaceUserId: string = ""
  name: string = ""
  email: string = ""
  title: string = ""

  constructor(
    data: {
      spaceUserId: string
      name: string
      email: string
      title: string
    } | null = null,
  ) {
    if (data) {
      this.spaceUserId = data.spaceUserId
      this.name = data.name
      this.email = data.email
      this.title = data.title
    }
  }
}
