import "reflect-metadata"
import { inter, roboto, robotoMono } from "core/utils/fonts"
import { appWithTranslation, useTranslation } from "next-i18next"
import React, { useMemo } from "react"
import { RecoilRoot } from "recoil"
import nextI18nConfig from "../next-i18next.config"

import "@fortawesome/fontawesome-svg-core/styles.css"
import Head from "next/head"
import { useRouter } from "next/router"
import AuthenticationProvider from "../components/authentication"
import "../styles/globals.css"
import "../styles/reactGrid.css"
import { disableDuplicateWarning } from "../utils/recoil"

global.console = disableDuplicateWarning(global.console)

import { Header } from "@/modules/navigation/containers/Header"
import { Loader } from "../components/spinners/loader"

import { Worker } from "@react-pdf-viewer/core"
import { ErrorBoundary } from "@sentry/nextjs"
import packageJson from "../package.json"
import Error500Page from "./500"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ThemeProvider } from "context/theme-context"
import { SWRConfig } from "swr"
import { NotificationsProvider } from "../context/notifications"
import PusherProvider from "../context/pusher-context"
import { SideBar } from "@/modules/navigation/containers/SideBar"
import { Snackbar } from "@/core/components/Snackbar"
import dayjs from "dayjs"

export const queryClient = new QueryClient()

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const { i18n } = useTranslation()
  dayjs.locale(i18n.language)
  const pdfjsWorkerVersion: string = useMemo(
    () => packageJson.dependencies["pdfjs-dist"],
    [],
  )

  return (
    <ErrorBoundary fallback={<Error500Page />}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <React.Suspense fallback={<Loader />}>
            <SWRConfig
              value={{
                revalidateOnFocus: false,
                revalidateOnReconnect: false,
              }}
            >
              <ThemeProvider>
                <Head>
                  <meta name="robots" content="noindex, nofollow" />
                  <title>Flextribe - Workforce Management System</title>
                  <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                  />
                </Head>
                <AuthenticationProvider>
                  <PusherProvider>
                    <NotificationsProvider />
                    <main
                      className={`max-w-screen bg-surface font-inter flex h-screen max-h-screen p-0 ${roboto.variable} ${inter.variable} ${robotoMono.variable}`}
                    >
                      <SideBar />
                      <div className="h-full w-full max-w-full">
                        <Header />
                        <div className="h-[calc(93vh)] w-full overflow-y-auto px-10 2xl:px-0">
                          <Worker
                            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsWorkerVersion}/build/pdf.worker.min.js`}
                          >
                            <Component {...pageProps} key={router.asPath} />
                          </Worker>
                        </div>
                      </div>
                      <div id="modal-root" />
                    </main>
                  </PusherProvider>
                </AuthenticationProvider>
              </ThemeProvider>
            </SWRConfig>
          </React.Suspense>
        </RecoilRoot>

        <Snackbar />
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

export default appWithTranslation(MyApp, nextI18nConfig)
