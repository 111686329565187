import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
} from "class-validator"

export function IsOnlyOneFilled(
  property: string,
  validationOptions?: ValidationOptions,
) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: "isOnlyOneFilled",
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [property],
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints
          const relatedValue = (args.object as any)[relatedPropertyName]

          // Validation passes if either the current property or the related property is filled
          const isCurrentFilled = value !== null && value !== ""
          const isRelatedFilled = relatedValue !== null && relatedValue !== ""

          return isCurrentFilled || isRelatedFilled
        },
        defaultMessage(args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints
          return `Either ${args.property} or ${relatedPropertyName} must be filled`
        },
      },
    })
  }
}
