import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { StringUtil } from "@/core/utils/string_util"
import { ContactModel } from "./contactModel"

@Serializable
export class ContactExtModel extends ContactModel {
  roles: string[] = []

  constructor(
    data: {
      spaceUserId: string
      name: string
      email: string
      title: string
      roles: string[]
    } | null = null,
  ) {
    super(data)
    if (data) {
      this.roles = data.roles
    }
  }
}
