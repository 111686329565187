import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { PagingModel } from "@/core/api/models/paging_model"
import { PriceCutModel } from "@/modules/prices/models/priceCutModel"

@Serializable
export class SupplierMainTaskPagingModel<T> extends PagingModel<T> {
  standardPriceCut: PriceCutModel | null = null

  constructor(
    rows: T[] = [],
    numRows: number = 0,
    skip: number = 0,
    num: number = 0,
    numInTabs: number[] = [],
    standardPriceCut: PriceCutModel | null = null,
  ) {
    super(rows, numRows, skip, num, numInTabs)
    this.standardPriceCut = standardPriceCut
  }
}
