import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { PagingModel } from "@/core/api/models/paging_model"

@Serializable
export class SupplierMainTaskSelectedPagingModel<T> extends PagingModel<T> {
  numSelected: number = 0

  constructor(
    rows: T[] = [],
    numRows: number = 0,
    skip: number = 0,
    num: number = 0,
    numInTabs: number[] = [],
    numSelected: number = 0,
  ) {
    super(rows, numRows, skip, num, numInTabs)
    this.numSelected = numSelected
  }
}
