import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { MainTaskModel } from "../mainTaskModel"
import { FileAttachment } from "../../customer/models/fileAttachment"
import { SupplierNewArgs } from "./supplierNewArgs"
import { SupplierListView } from "../../supplier/models/supplierListView"
import { PriceRootModel } from "@/modules/prices/models/priceRootModel"
import { SubAreaModel } from "@/modules/prices/models/subAreaModel"
import { ContactExtModel } from "../contactExtModel"
import { Type } from "class-transformer"
import { IsObject, ValidateNested } from "class-validator"
import { PriceCutModel } from "@/modules/prices/models/priceCutModel"

@Serializable
export class MainTaskSaveArgs {
  @IsObject()
  @ValidateNested({
    groups: [
      "about_customer",
      "agreement_type",
      "agreement_information",
      "agreement_owner",
      "dates",
      "subareas",
      "summary",
    ],
  })
  @Type(() => MainTaskModel)
  model: MainTaskModel = new MainTaskModel()
  // suppliers: SupplierListView[] = []
  files: FileAttachment[] = []
  @ValidateNested({ groups: ["subareas"] })
  subAreas: SubAreaModel[] = []
  priceRoot: PriceRootModel = new PriceRootModel()
  responsibles: ContactExtModel[] = []
  contacts: ContactExtModel[] = []
  standardPriceCut: PriceCutModel | null = null

  constructor(init?: Partial<MainTaskSaveArgs>) {
    this.model = new MainTaskModel()
    // this.suppliers = []
    this.files = []
    this.subAreas = []
    this.priceRoot = new PriceRootModel()
    this.responsibles = []
    this.contacts = []
    Object.assign(this, init)
  }
}
