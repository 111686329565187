import { Serializable } from "@/core/api/decorators/serializable_decorator"

@Serializable
export class PagingArgs {
  search: string = ""
  skip: number = 0
  num: number = 25

  constructor(init?: Partial<PagingArgs>) {
    Object.assign(this, init)
  }
}
