import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { AssignedContact } from "./assignedContacts"
import { SupplierSubAreaModel } from "@/modules/prices/models/supplierSubAreaModel"
import { PriceType } from "@/modules/prices/constants/priceType"
import { ContactExtModel } from "@/modules/tasks/models/contactExtModel"
import { SimpleModel } from "./simpleModel"
import { FileAttachment } from "@/modules/tasks/customer/models/fileAttachment"

@Serializable
export class MinibidTask {
  uid: string
  _start_date: string
  _candidate_template: string
  _numHoursWeek: number
  _rfq_sender: SimpleModel = new SimpleModel()
  _end_date: string
  _contacts: ContactExtModel[] = []
  _customer: SimpleModel = new SimpleModel()
  _parent_task_name: string
  _subtitle: string
  _name: string
  _case_number: string
  interested: boolean
  _max_candidates: string
  _acceptance_period: string
  _work_place: string
  _extension_months: number
  created: string
  _continuation: boolean
  _description: string
  _intermediate_sale: boolean
  _internal_deadline: string
  _files: FileAttachment[] = []
  _assigned_contacts: AssignedContact[]
  subAreas: SupplierSubAreaModel[] = []
  version: number = 0
  priceType: number = PriceType.Free
  currency: string = ""

  constructor(
    data: {
      uid: string
      _start_date: string
      _candidate_template: string
      _numHoursWeek: number
      _rfq_sender: any
      _end_date: string
      _contacts: any[]
      _customer: any
      _parent_task_name: string
      _subtitle: string
      _name: string
      _case_number: string
      interested: boolean
      _max_candidates: string
      _acceptance_period: string
      _work_place: string
      _extension_months: number
      created: string
      _continuation: boolean
      _description: string
      _intermediate_sale: boolean
      _internal_deadline: string
      _files: any[]
      _assigned_contacts: AssignedContact[]
      subAreas: SupplierSubAreaModel[]
      version: number
      priceType: number
      currency: string
    } | null = null,
  ) {
    if (data) {
      this.uid = data.uid
      this._start_date = data._start_date
      this._candidate_template = data._candidate_template
      this._numHoursWeek = data._numHoursWeek
      this._rfq_sender = data._rfq_sender
      this._end_date = data._end_date
      this._contacts = data._contacts
      this._customer = data._customer
      this._parent_task_name = data._parent_task_name
      this._subtitle = data._subtitle
      this._name = data._name
      this._case_number = data._case_number
      this.interested = data.interested
      this._max_candidates = data._max_candidates
      this._acceptance_period = data._acceptance_period
      this._work_place = data._work_place
      this._extension_months = data._extension_months
      this.created = data.created
      this._continuation = data._continuation
      this._description = data._description
      this._intermediate_sale = data._intermediate_sale
      this._internal_deadline = data._internal_deadline
      this._files = data._files
      this._assigned_contacts = data._assigned_contacts
      this.subAreas = data.subAreas
      this.version = data.version
      this.priceType = data.priceType
      this.currency = data.currency
    }
  }
}

/*
  constructor(
    uid: string,
    _start_date: string,
    _candidate_template: string,
    _numHoursWeek: number,
    _rfq_sender: { uid: string; name: string },
    _end_date: string,
    // _contacts: {
    //   uid: string
    //   email: string
    //   name: string
    //   space_user_uid: string
    //   role: string[]
    // }[],
    _customer: {
      uid: string
      name: string
      customerType: string | null
      reference: string
    },
    _parent_task_name: string,
    _subtitle: string,
    _name: string,
    _case_number: string,
    interested: boolean,
    _max_candidates: string,
    _acceptance_period: string,
    _work_place: string,
    _extension_months: string,
    created: string,
    _continuation: boolean,
    _description: string,
    _intermediate_sale: boolean,
    _internal_deadline: string,
    _files: { uid: string; name: string; url: string }[],
    _assigned_contacts: AssignedContact[],
    subAreas: SupplierSubAreaModel[],
    version: number,
    priceType: number
  ) {
    // super(
    //   _assigned_contacts[0].email,
    //   _assigned_contacts[0].name,
    //   _assigned_contacts[0].title,
    //   _assigned_contacts[0].space_user_uid,
    //   _assigned_contacts[0].role,
    // )
    this.uid = uid
    this._start_date = _start_date
    this._candidate_template = _candidate_template
    this._numHoursWeek = _numHoursWeek
    this._rfq_sender = _rfq_sender
    this._end_date = _end_date
    // this._contacts = _contacts
    this._customer = _customer
    this._parent_task_name = _parent_task_name
    this._subtitle = _subtitle
    this._name = _name
    this._case_number = _case_number
    this.interested = interested
    this._max_candidates = _max_candidates
    this._acceptance_period = _acceptance_period
    this._work_place = _work_place
    this._extension_months = _extension_months
    this.created = created
    this._continuation = _continuation
    this._description = _description
    this._intermediate_sale = _intermediate_sale
    this._internal_deadline = _internal_deadline
    this._files = _files
    this._assigned_contacts = _assigned_contacts
    this.subAreas = subAreas
    this.version = version
    this.priceType = priceType
  }
*/
