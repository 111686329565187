import { Serializable } from "@/core/api/decorators/serializable_decorator"

@Serializable
export class LeadListView {
  id: string = ""
  firstname: string = ""
  lastname: string = ""
  email: string = ""
  roles: string[] = []

  constructor(
    data: {
      id: string
      firstname: string
      lastname: string
      email: string
      roles: string[]
    } | null = null,
  ) {
    if (data) {
      this.id = data.id
      this.firstname = data.firstname
      this.lastname = data.lastname
      this.email = data.email
      this.roles = data.roles
    }
  }
}
