import { changeSpace, selectedSpaceAtom } from "@/atoms/spaces"
import { NotificationSheet } from "@/modules/notifications/containers/NotificationSheet"
import { useTranslation } from "next-i18next"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { Icon } from "@/core/components/icon/Icon"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/core/components/Menu"
import { Divider } from "@/core/components/Divider"
import { Label } from "@/core/components/Label"
import { signOut } from "@/utils/api/authentication"
import useNavigationHistory from "@/core/hooks/useNavigationHistory"
import { useRouter } from "next/router"

export const Header = () => {
  const { t } = useTranslation("navigation")
  const space = useRecoilValue(selectedSpaceAtom)
  const _changeSpace = useSetRecoilState(changeSpace)
  const { history, goBack } = useNavigationHistory()
  const router = useRouter()

  return (
    <div className="bg-surface flex h-20 items-center justify-end pr-12 2xl:justify-between">
      <div
        className="text-on-surface-variant group ml-5 hidden cursor-pointer items-center gap-2 text-sm 2xl:flex"
        onClick={goBack}
      >
        {history.length > 1 && (
          <>
            <Icon
              icon={"arrow_back"}
              className="text-2xl transition-transform duration-300 ease-in-out group-hover:-translate-x-1"
            />
            <p className="opacity-100 transition-opacity duration-300 ease-in-out group-hover:opacity-100">
              {t("back_to_previous_page")}
            </p>
          </>
        )}
      </div>
      <div className="flex items-center gap-6">
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="hover:bg-secondary-container/90 active:bg-secondary-container focus:bg-secondary-container flex cursor-pointer items-center rounded-md p-1 pl-3 text-sm">
              {space.name}

              <Icon icon={"arrow_drop_down"} className="text-2xl" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="bg-container-base">
            <div className="flex items-center gap-2 p-2 py-4">
              <Icon
                icon={"corporate_fare"}
                className="text-surface bg-on-surface flex h-10 w-10 items-center justify-center rounded-full p-2 text-2xl"
              />
              <div>
                <div className="text-sm font-semibold">{space.name}</div>
                <div className="text-xs">
                  {t("vat", { value: space.reference })}
                </div>
              </div>
            </div>

            <Divider className="bg-on-surface-variant/12 my-0" />
            <div>
              <Label
                size="sm"
                className="hover:bg-on-surface/10 flex cursor-pointer items-center gap-2 px-3 py-4"
                onClick={() => _changeSpace(true)}
              >
                <Icon icon="swap_horiz" className="text-xl" />
                {t("change_space")}
              </Label>
            </div>
            <div>
              <Label
                size="sm"
                className="hover:bg-on-surface/10 flex cursor-pointer items-center gap-2 px-3 py-4"
                onClick={() => router.push("/settings")}
              >
                <Icon icon="settings" className="text-xl" />
                {t("settings")}
              </Label>
            </div>
            <Divider className="bg-on-surface-variant/12 my-0" />
            <div>
              <Label
                size="sm"
                className="hover:bg-on-surface/10 flex cursor-pointer items-center gap-2 px-3 py-4"
                onClick={() => signOut()}
              >
                <Icon icon="logout" className="text-xl" />
                {t("logout")}
              </Label>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>

        <NotificationSheet />
      </div>
    </div>
  )
}
