import { v4 as uuid } from "uuid"
import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { PriceCategoryBaseModel } from "./categories/priceCategoryBaseModel"
import { PriceType } from "../constants/priceType"

@Serializable
export class PriceRootModel {
  uid: string = uuid()
  priceType: number | null = null
  currency: string = ""
  isActive: boolean = false
  version: number = 1
  activatedAt: string = ""
  expiredAt: string = ""
  categories: PriceCategoryBaseModel[] = []

  constructor(
    data: {
      uid: string
      priceType: number
      currency: string
      isActive: boolean
      version: number
      activatedAt: string
      expiredAt: string
      categories: any[]
    } | null = null,
  ) {
    if (data) {
      this.uid = data.uid
      this.priceType = data.priceType
      this.currency = data.currency
      this.isActive = data.isActive
      this.version = data.version
      this.activatedAt = data.activatedAt
      this.expiredAt = data.expiredAt
      this.categories = data.categories
    }
  }
}
