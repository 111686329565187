import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { TaskKanbanStatus } from "../../core/constants/taskKanbanStatus"
import { CandidateListView } from "@/modules/candidate/models/candidateListView"
import { LeadListView } from "../../supplier/models/leadListView"

@Serializable
export class CustomerTaskListView {
  uid: string = ""
  name: string = ""
  departmentId: string = ""
  departmentName: string = ""
  caseNumber: string = ""
  internalDeadline: string = ""
  customerDeadline: string = ""
  status: string = ""
  startDate: string = ""
  endDate: string = ""
  hasExtensionOption: boolean = false
  kanbanStatus: number = TaskKanbanStatus.NotDefined
  stepIndex: number = 0
  customerId: string = ""
  customerName: string = ""
  mainTaskName: string = ""
  candidates: CandidateListView[] = []
  leads: LeadListView[] = []

  constructor(
    data: {
      uid: string
      name: string
      departmentId: string
      departmentName: string
      caseNumber: string
      internalDeadline: string
      customerDeadline: string
      status: string
      startDate: string
      endDate: string
      kanbanStatus: number
      stepIndex: number
      customerId: string
      customerName: string
      mainTaskName: string
      candidates: any[]
      leads: any[]
    } | null = null,
  ) {
    if (data) {
      this.uid = data.uid
      this.name = data.name
      this.departmentId = data.departmentId
      this.departmentName = data.departmentName
      this.caseNumber = data.caseNumber
      this.internalDeadline = data.internalDeadline
      this.customerDeadline = data.customerDeadline
      this.status = data.status
      this.startDate = data.startDate
      this.endDate = data.endDate
      this.kanbanStatus = data.kanbanStatus
      this.stepIndex = data.stepIndex
      this.customerId = data.customerId
      this.customerName = data.customerName
      this.mainTaskName = data.mainTaskName
      this.candidates = data.candidates
      this.leads = data.leads
    }
  }
}
