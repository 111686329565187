import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { FileAttachment } from "./fileAttachment"

@Serializable
export class CustomerMainTaskSimpleListView {
  uid: string = ""
  name: string = ""
  description: string = ""
  ownerName: string = ""
  files: FileAttachment[] = []

  constructor(
    data: {
      uid: string
      name: string
      description: string
      ownerName: string
      files: any[]
    } | null = null,
  ) {
    if (data) {
      this.uid = data.uid
      this.name = data.name
      this.description = data.description
      this.ownerName = data.ownerName
      this.files = data.files
    }
  }
}
