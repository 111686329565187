import { Serializable } from "@/core/generated/api/decorators/serializable_decorator"
import { TaskStatus } from "../constants/taskStatus"
import { IsNotEmpty, IsOptional as IsOptionalValidator } from "class-validator"
import { v4 } from "uuid"
import { IsOnlyOneFilled } from "../core/utils/validators/IsOnlyOneFIlled"
import { IsAcceptancePeriodRequired } from "../core/utils/validators/IsAcceptancePeriodRequired"

@Serializable
export class RFQTaskModel {
  @IsNotEmpty({
    message: "about_customer.agreement_error",
    groups: ["about_customer"],
  })
  uid: string = v4()

  @IsNotEmpty({ message: "error_fill", groups: ["details", "request_details"] })
  name: string = ""

  @IsOptionalValidator({ groups: ["details"] })
  caseNumber: string = ""

  @IsOptionalValidator({ groups: ["details"] })
  subtitle: string = ""

  description: string = ""

  @IsOptionalValidator({ groups: ["prices"] })
  state: string = TaskStatus.Drafted

  @IsOptionalValidator({ groups: ["dates"] })
  @IsOnlyOneFilled("customerDeadline", {
    message: "Either customerDeadline or internalDeadline must be filled",
    groups: ["dates"],
  })
  internalDeadline: string = ""

  @IsOptionalValidator({ groups: ["dates"] })
  @IsOnlyOneFilled("internalDeadline", {
    message: "Either customerDeadline or internalDeadline must be filled",
    groups: ["dates"],
  })
  customerDeadline: string = ""

  @IsNotEmpty({ message: "error_fill", groups: ["details"] })
  maxNumberCandidates: number = null

  @IsNotEmpty({ message: "error_fill", groups: ["details"] })
  workplace: string = ""

  @IsNotEmpty({ message: "error_fill", groups: ["details"] })
  startDate: string = ""

  @IsNotEmpty({ message: "error_fill", groups: ["details"] })
  endDate: string = ""

  @IsNotEmpty({ message: "error_fill", groups: ["details"] })
  isContinued: boolean = null

  @IsNotEmpty({ message: "error_fill", groups: ["dates"] })
  intermediateSale: boolean | null = null

  @IsAcceptancePeriodRequired({ message: "error_fill", groups: ["dates"] })
  acceptancePeriod: string = ""

  @IsNotEmpty({
    message: "about_customer.customer_error",
    groups: ["about_customer"],
  })
  customerId: string = ""
  customerName: string = ""
  stepIndex: number = 0
  numHoursWeek: number = 37

  @IsOptionalValidator({ groups: ["details"] })
  extensionMonths: number = 0
  version: number = 0

  constructor(init?: Partial<RFQTaskModel>) {
    Object.assign(this, init)
  }
}
