import { Dictionary } from "@/core/collections/dictionary"

interface SerializableClass {
  __type?: string
}

const classRegistry: Dictionary<any> = {}

// export function Serializable(constructor: Function) {
//   let className: string = constructor.name
//   if (
//     className.endsWith("1") ||
//     className.endsWith("2") ||
//     className.endsWith("3")
//   ) {
//     className = className.slice(0, -1)
//   }

//   classRegistry[className] = constructor
// }

export function Serializable<T extends { new (...args: any[]): {} }>(
  constructor: T,
) {
  let className: string = constructor.name
  if (
    className.endsWith("1") ||
    className.endsWith("2") ||
    className.endsWith("3")
  ) {
    className = className.slice(0, -1)
  }

  classRegistry[className] = constructor as unknown as {
    new (...args: any[]): {}
  }

  // Set the static __type property on the class constructor
  ;(constructor as any).__type = className

  // return class extends constructor {
  //   static __type = className
  // }
}

export function getClassType(className: string): any {
  return classRegistry[className]
}

export const registerClass = <T>(ctor: new () => T) => {
  let instance = new ctor()
  ;(instance as any).__type = ctor.name
}
