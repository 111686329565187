import { Badge } from "core/components/Badge"
import Link from "next/link"
import { Icon } from "@/core/components/icon/Icon"
import Avatar from "@/components/avatar"
import { useEffect, useState } from "react"
import * as Collapsible from "@radix-ui/react-collapsible"
import { cn } from "@/utils/helpers"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import type { TSideBarItem } from "../types/SideBarItem"

export const SideBarFullItem = ({
  label,
  icon,
  href,
  value,
  imageSrc,
  subItem,
  locked,
}: TSideBarItem) => {
  const [open, setOpen] = useState(false)
  const router = useRouter()
  const { t } = useTranslation("navigation")
  const active = router.asPath === href

  const link = (localHref: string) => (locked ? "/sales" : localHref)

  //if any of the subItem is active, then the parent item should be open
  const isSubItemActive =
    subItem !== null &&
    subItem.length > 1 &&
    subItem?.some((item) => router.asPath === item.href)
  useEffect(() => {
    if (isSubItemActive) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [isSubItemActive])

  if (subItem === null) {
    return (
      <Link href={link(href)}>
        <div
          className={cn(
            "text-on-surface-variant hover:bg-secondary-container/90 active:bg-secondary-container focus:bg-secondary-container group relative flex h-14 cursor-pointer items-center justify-between rounded-full pl-4",
            active && "bg-secondary-container text-on-secondary-container",
          )}
        >
          <div className="flex items-center">
            {(imageSrc || icon) && (
              <div className="relative mr-2 flex w-6 flex-col items-center">
                {imageSrc ? (
                  <Avatar email={imageSrc} size={32} />
                ) : (
                  <Icon icon={icon} className="text-2xl" />
                )}
              </div>
            )}
            <div className="ml-1 text-sm">{t(label)}</div>
          </div>
          {value > 0 && (
            <Badge variant="plain" className="pr-5" count={value} />
          )}
          {locked && (
            <div className="bg-container-high text-on-surface-variant mr-5 flex h-5 w-5 items-center justify-center rounded">
              <Icon icon="lock" className="!text-lg !leading-none" />
            </div>
          )}
        </div>
      </Link>
    )
  }
  return (
    <Collapsible.Root open={open} onOpenChange={setOpen}>
      {subItem.length === 1 ? (
        <Link href={link(subItem[0].href)}>
          <div
            className={`text-on-surface-variant hover:bg-secondary-container/90 active:bg-secondary-container focus:bg-secondary-container mt-2 flex h-14 cursor-pointer items-center justify-between rounded-full pl-4 ${
              router.asPath === subItem[0].href &&
              "bg-secondary-container text-on-secondary-container hover:bg-secondary-container"
            }`}
          >
            <div className="flex items-center">
              {(subItem[0].imageSrc || subItem[0].icon) && (
                <div className="mr-2 flex w-6 flex-col items-center">
                  {subItem[0].imageSrc ? (
                    <Avatar email={subItem[0].imageSrc} size={32} />
                  ) : (
                    <Icon icon={subItem[0].icon} className="text-2xl" />
                  )}
                </div>
              )}
              <div className="ml-1 text-sm">{t(subItem[0].label)}</div>
            </div>
            {subItem[0].value > 0 && (
              <Badge
                variant="plain"
                className="pr-5"
                count={subItem[0].value}
              />
            )}
          </div>
        </Link>
      ) : (
        <Collapsible.Trigger asChild>
          <div
            className={cn(
              `text-on-surface-variant hover:bg-secondary-container/90 active:bg-secondary-container focus:bg-secondary-container flex h-14 cursor-pointer select-none items-center justify-between rounded-full pl-4`,
              active &&
                "bg-secondary-container text-on-secondary-container hover:bg-secondary-container",
              open && "bg-on-surface/10 text-on-surface-variant",
            )}
          >
            <div className="flex items-center">
              <div className="mr-3 flex w-6 flex-col items-center">
                {imageSrc ? (
                  <Avatar email={imageSrc} size={32} />
                ) : (
                  <Icon icon={icon} className="text-2xl" />
                )}
              </div>
              <div className="text-sm">{t(label)}</div>
            </div>
            <Icon
              icon="arrow_drop_down"
              className={cn("mr-4 text-2xl", open && "rotate-180")}
            />
          </div>
        </Collapsible.Trigger>
      )}
      <Collapsible.Content className="ml-5">
        {subItem?.map((child, index) => (
          <SideBarFullItem
            key={index}
            label={child.label}
            icon={null}
            href={child.href}
            value={child.value}
            imageSrc={child.imageSrc}
            subItem={null}
            locked={child.locked}
          />
        ))}
      </Collapsible.Content>
    </Collapsible.Root>
  )
}
