import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { PriceCategoryBaseModel } from "./priceCategoryBaseModel"
import { da } from "date-fns/locale"

@Serializable
export class PriceCategoryFreeModel extends PriceCategoryBaseModel {
  amount: number = 0

  constructor(
    data: {
      uid: string
      parentId: string
      name: string
      subtitle: string
      priceType: number
    } | null = null,
  ) {
    super(data)
    if (data) {
    }
  }
}
