import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { ContactModel } from "../../models/contactModel"

@Serializable
export class SupplierListView {
  uid: string = ""
  name: string = ""
  contacts: ContactModel[] = []

  constructor(
    data: {
      uid: string
      name: string
      contacts: ContactModel[]
    } | null = null,
  ) {
    if (data) {
      this.uid = data.uid
      this.name = data.name
      this.contacts = data.contacts
    }
  }
}
